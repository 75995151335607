.foto {
    display: block;
    width: 100%;
    height: 425px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @include breakpoint(768px, max) {
        height: 225px;
    }
}
