.socio {
    margin-top: 97px;
    .container {
        max-width: 750px;
        margin-top: 50px;
        h1 {
            display: table;
            width: auto;
            background-color: $azul;
            color: #ffffff;
            font-size: 20px;
            line-height: 20px;
            padding: 22px 130px;
            margin-bottom: 50px;
        }
        .texto {
            color: $azul;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 50px;
        }
    }
}
