.publicacoes {
    .listagem {
        margin-top: 60px;
        .container {
            max-width: 845px;
            a {
                display: table;
                width: 100%;
                padding: 5px 9px 6px 10px;
                @include breakpoint(768px, max) {
                    margin-bottom: 30px;
                }
                h2 {
                    font-size: 24px;
                    line-height: 30px;
                    color: #ffffff;
                    text-align: left;
                    border-bottom: #FFF 1px solid;
                    padding-bottom: 4px;
                    font-weight: bold;
                }
                .texto {
                    text-align: left;
                    font-size: 20px;
                    line-height: 30px;
                    font-family: $roboto;
                    color: #ffffff;
                    font-weight: 300;
                }
                .botao {
                    float: right;
                    display: table;
                    padding: 5px 10px;
                    background-color: #ffffff;
                    i {
                        color: $azul;
                    }
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}