.form-group {
    margin-bottom: 5px;
}

.form-control {
    font-family: $fontDefault;
    border-radius: 0;
    background-color: $azul;
    border: #e1e1e1 0px solid;
    box-shadow: none;
    font-size: 19px;
    color: #FFF;
    font-weight: 400;
    padding: 10px 24px;
    height: 52px;
}