.conteudo {
    display: block;
    background-color: $azul;
    color: #ffffff;
    font-family: $roboto;
    font-size: 20px;
    text-align: center;
    line-height: 24px;
    padding: 60px 0;
    font-weight: 300;
    .container {
        max-width: 900px;
    }
}