.btn {
    &.btn-blue {
        position: relative;
        font-family: $fontDefault;
        background-color: $azul;
        border-radius: 0;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        text-transform: uppercase;
        color: #ffffff;
        padding: 15px 20px;
        text-align: left;
    }
    &:hover {
        background-color: #ffffff;
        color: $azul;
        cursor: pointer;
    }
}
