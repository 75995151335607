.socios {
    .listagem {
        a {
            height: auto;
            padding: 0;
            margin-top: 0;
            figure {
                position: relative;
                height: 100%;
                max-height: calc(100vh - 152px);
                width: 100%;
                display: block;
                overflow: hidden;
                margin: 0;
                img {
                    filter: grayscale(100%);
                    width: 100%;
                    transition: all .3s ease;
                }
            }
            .dados {
                display: block;
                width: 100%;
                background-color: $azul;
                position: absolute;
                bottom: 50px;
                h2 {
                    display: block;
                    padding: 20px;
                    text-align: center;
                    color: #ffffff;
                    font-size: 20px;
                    line-height: 20px;
                }
            }
            &:hover {
                figure {
                    img {
                        filter: grayscale(0%);
                    }
                }
            }
        }
    }
}
