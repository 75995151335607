/*
  SASS Variables for project
 */
$version: "v1.0.0";
$author: "Alexander Moraes";

// COLORS DEFAULT
$valid: #2ecc71;
$error: #FF0000;

$azul: #192440;
$preto: #000000;
$cinza: #4A4A4A;
$vermelho: #D0021B;
$vermelhoHover: #A90115;

//Tamanho Container
$containerWidth: 1170px;

//Font Padrão do Site
$fontDefault: 'Timeless', sans-serif;;
$roboto: 'Roboto', sans-serif;

$corBarraResponsivo: #192440;

// PATHS
$fontsSRC: "assets/fonts/";
$img: "assets/imgs/";


// BREAKPOINTS
$breakpoints: (max: (xs: 767px), min: (sm: 768px, md: 992px, lg: 1200px));