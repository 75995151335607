/*!
Theme Name: Lima Marques Advogados
Theme URI: http://www.agenciadominio.com.br/
Description: Site desenvolvido especificamente para uso da Lima Marques Advogados;
Author: Ag&ecirc;ncia Dom&iacute;nio
Author URI: http://www.agenciadominio.com.br/
Version: 1.0
*/

// Imports
@import "mixins/mixins";
@import "commom/imports";
@import "commom/variables";
@import "commom/fonts";
@import "commom/default";
@import "commom/padroes";
@import "commom/loader";
@import "commom/compartilhe";
@import "commom/titulo";
@import "commom/whatsapp";

//Components
@import "components/buttons";
@import "components/select";
@import "components/inputs";
@import "components/h1";
@import "components/paginacao";

//Layout Site
@import "header";
@import "menu";
@import "vitrine";
@import "foto";
@import "conteudo";
@import "escritorio";
@import "areasAtuacao";
@import "socios";
@import "socio";
@import "noticias";
@import "noticia";
@import "publicacoes";
@import "publicacao";
@import "contato";
@import "footer";