header {
    position: fixed;
    z-index: 10;
    display: block;
    width: 100%;
    height: auto;
    padding: 0px 0 40px;
    top: 0;
    @include bgBackNone();
    @include breakpoint(768px, max) {
        padding: 0;
    }
    .logo {
        background-color: $azul;
        a {
            display: table;
            margin: auto;
        }
    }
}