.noticia {
    margin-top: 97px;
    .container {
        max-width: 750px;
        margin-top: 50px;
        h1 {
            display: table;
            width: auto;
            color: $azul;
            font-size: 24px;
            line-height: 32px;
            padding: 0 50px 4px 0;
            margin-bottom: 50px;
            border-bottom: $azul 1px solid;
        }
        .texto {
            color: $azul;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 50px;
        }
    }
    &.archive {
        a {
            h2 {
                color: $azul;
                font-size: 20px;
                line-height: 24px;
                font-weight: bold;
            }
            .texto {

            }
            &:hover {
                text-decoration: none;
                h2, .texto {
                    color: #333333;
                }
            }
        }
    }
}
