footer {
    background-color: $azul;
    padding: 20px 0;
    .copyright {
        color: #ffffff;
        font-family: $roboto;
        font-size: 20px;
        line-height: 20px;
        font-weight: 300;
        padding-top: 4px;
        @include breakpoint(768px, max) {
            font-size: 11px;
        }
    }
}
