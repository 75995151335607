.contato {
    display: block;
    width: 100%;
    height: auto;
    min-height: calc(100vh - 97px);
    
    background-size: cover;
    h2 {
        font-size: 36px;
        line-height: 36px;
        color: #ffffff;
    }
    form {
        margin-bottom: 30px;
        .botao {
            text-align: right;
            button {
                display: inline-block;
            }
        }
    }
    .dados {
        position: relative;
        font-family: $roboto;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        margin-bottom: 30px;
        @include breakpoint(768px, max) {
            margin-top: 40px;
        }
        .separador {
            @include breakpoint(768px, max) {
                margin-bottom: 20px;
            }
            &:after {
                position: absolute;
                content: '';
                width: 1px;
                height: 100%;
                right: 15px;
                background-color: #ffffff;
                top: 0;
                @include breakpoint(768px, max) {
                    display: none;
                }
            }
        }
        a {
            color: #ffffff;
            font-size: 14px;
            &:hover {
                text-decoration: none;
                color: #ffffff;
            }
        }
    }
}