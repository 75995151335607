@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');
//font-family: 'Roboto', sans-serif;

@font-face {
    font-family: 'Timeless';
    src: url($fontsSRC + 'Timeless-Normal.woff') format('woff'),
         url($fontsSRC + 'Timeless-Normal.ttf') format('truetype'),
         url($fontsSRC + 'Timeless-Normal.svg#Timeless-Normal') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Timeless';
    src: url($fontsSRC + 'Timeless.eot');
    src: url($fontsSRC + 'Timeless.woff2') format('woff2'),
         url($fontsSRC + 'Timeless.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Timeless';
    src: url($fontsSRC + 'Timeless-Bold.eot');
    src: url($fontsSRC + 'Timeless-Bold.woff2') format('woff2'),
         url($fontsSRC + 'Timeless-Bold.woff') format('woff'),
         url($fontsSRC + 'Timeless-Bold.ttf') format('truetype'),
         url($fontsSRC + 'Timeless-Bold.svg#Timeless-Bold') format('svg'),
         url($fontsSRC + 'Timeless-Bold.eot?#iefix') format('embedded-opentype');
    font-weight: bold;
    font-style: normal;
  }
  
    