.areasAtuacao {
    .areas {
        margin-top: 60px;
        font-family: $fontDefault;
        font-weight: bold;
        .container {
            max-width: 1170px;
            text-align-last: left;
            .col-12 {
                margin-bottom: 30px;
            }
        }
    }
}