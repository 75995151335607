.noticias {
    .listagem {
        .container {
            max-width: 845px;
            a {
                display: table;
                width: 100%;
                padding: 5px 9px 6px 10px;
                @include breakpoint(768px, max) {
                    margin-bottom: 30px;
                }
                h2 {
                    font-size: 20px;
                    line-height: 24px;
                    color: #ffffff;
                    text-align: left;
                    border-bottom: #FFF 1px solid;
                    padding-bottom: 4px;
                    font-weight: bold;
                }
                .texto {
                    text-align: left;
                    font-size: 18px;
                    line-height: 24px;
                    font-family: $roboto;
                    color: #ffffff;
                    font-weight: 300;
                }
                .botao {
                    float: right;
                    display: table;
                    padding: 5px 10px;
                    background-color: #ffffff;
                    i {
                        color: $azul;
                    }
                }
                &:hover {
                    text-decoration: none;
                }
            }
            .leiaMais {
                padding: 30px 0 0;
                a {
                    display: table;
                    margin: auto;
                    width: auto;
                    border: #fff 2px solid;
                    padding: 7px 15px;
                    text-transform: uppercase;
                    color: #ffffff;
                    font-size: 16px;
                }
            }
        }
    }
}